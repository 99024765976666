const isProduction = process.env.NODE_ENV === 'production'

const tracker = {
  initiateCheckout: () => {
    console.log(`[tracker] initiateCheckout`)
    if (!isProduction) return null
    if (window && window.fbq) {
      window.fbq('track', 'InitiateCheckout')
    }
    // if (window.heap) {
    //   window.heap.track('InitiateCheckout')
    // }
  },
  purchase: (price) => {
    console.log(`[tracker] purchase, price: ${price}`)
    if (!isProduction) return null
    if (!price) return null
    const value = +(price / 100).toFixed(2)
    if (window.fbq) {
      console.log('tracking purchase', value)
      window.fbq('track', 'Purchase', { value, currency: 'USD' })
    }
    // if (window.heap) {
    //   window.heap.track('Purchase', { dollars: value, item: 'Furby' })
    // }
  },
  completeRegistration: (userId, email) => {
    console.log(`[tracker] completeRegistration: ${email} - ${userId}`)
    if (!isProduction) return null
    if (window.po) {
      window.po('customers', 'signup', {
        data: {
          key: userId,
          name: email,
          email,
        },
      })
    }

    if (window.fbq) {
      console.log('tracing complete Registration')
      window.fbq('track', 'CompleteRegistration')
    }
    // if (window.heap) {
    //   window.heap.track('CompleteRegistration')
    // }
  },
}

export default tracker
