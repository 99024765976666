import { message } from 'antd'
import { handleActions } from 'redux-actions'

import fetch from '../../../services/fetch'
import logger from '../../../services/errorlogger'

export const actionTypes = {
  FLUSH_BILLING_DETAILS: 'FLUSH_BILLING_DETAILS',
  BILLING_DETAILS_LOADING: 'BILLING_DETAILS_LOADING',
  BILLING_DETAILS_SUCCESS: 'BILLING_DETAILS_SUCCESS',
  BILLING_DETAILS_ERROR: 'BILLING_DETAILS_ERROR',
  CHANGE_BILLING_DETAILS_LOADING: 'CHANGE_BILLING_DETAILS_LOADING',
  CHANGE_BILLING_DETAILS_SUCCESS: 'CHANGE_BILLING_DETAILS_SUCCESS',
  CHANGE_BILLING_DETAILS_ERROR: 'CHANGE_BILLING_DETAILS_ERROR',
}

export const actions = {
  flushBillingDetails: () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.FLUSH_BILLING_DETAILS })
  },
  fetchBillingDetails: () => async (dispatch, getState) => {
    try {
      dispatch({ type: actionTypes.BILLING_DETAILS_LOADING })
      const result = await fetch.get(`/billing/details/billing-details`)
      const { billingDetails } = result.data
      const { plan } = billingDetails
      // if (window.heap) {
      // window.heap.addUserProperties({ plan })
      // }
      dispatch({ type: actionTypes.BILLING_DETAILS_SUCCESS, billingDetails })
    } catch (e) {
      console.log('error', e)
      logger.logError(e)
      dispatch({ type: actionTypes.BILLING_DETAILS_ERROR })
    }
  },
  changeBillingDetails:
    (values, notify = true) =>
    async (dispatch, getState) => {
      try {
        dispatch({ type: actionTypes.CHANGE_BILLING_DETAILS_LOADING })
        const result = await fetch.patch(`/billing/details/billing-details`, values)
        dispatch({ type: actionTypes.CHANGE_BILLING_DETAILS_SUCCESS })
        if (notify) message.success('Billing information has been changed')
        dispatch(actions.fetchBillingDetails())
      } catch (e) {
        console.log('error', e)
        logger.logError(e)
        dispatch({ type: actionTypes.CHANGE_BILLING_DETAILS_ERROR })
        message.error(
          'There has been an error changing your details. Please reach out to support of the error persists'
        )
      }
    },
}

export const getDefaultState = () => ({
  billingDetails: null,
  billingDetailsLoading: false,
  billingDetailsError: false,
  billingDetailsSuccess: false,

  changeBillingDetailsLoading: false,
  changeBillingDetailsError: false,
  changeBillingDetailsSuccess: false,
})

export default handleActions(
  {
    [actionTypes.FLUSH_BILLING_DETAILS]: () => getDefaultState(),
    [actionTypes.BILLING_DETAILS_LOADING]: (state) => ({
      ...state,
      billingDetailsLoading: true,
      billingDetailsError: false,
      billingDetailsSuccess: false,
      billingDetails: null,
    }),
    [actionTypes.BILLING_DETAILS_SUCCESS]: (state, { billingDetails }) => {
      return {
        ...state,
        billingDetailsLoading: false,
        billingDetailsError: false,
        billingDetailsSuccess: false,
        billingDetails,
      }
    },
    [actionTypes.BILLING_DETAILS_ERROR]: (state) => {
      return {
        ...state,
        billingDetailsLoading: false,
        billingDetailsError: true,
        billingDetailsSuccess: false,
        billingDetails: null,
      }
    },
    [actionTypes.CHANGE_BILLING_DETAILS_LOADING]: (state) => ({
      ...state,
      changeBillingDetailsLoading: true,
      changeBillingDetailsError: false,
      changeBillingDetailsSuccess: false,
    }),
    [actionTypes.CHANGE_BILLING_DETAILS_SUCCESS]: (state) => {
      return {
        ...state,
        changeBillingDetailsLoading: false,
        changeBillingDetailsError: false,
        changeBillingDetailsSuccess: true,
      }
    },
    [actionTypes.CHANGE_BILLING_DETAILS_ERROR]: (state) => {
      return {
        ...state,
        changeBillingDetailsLoading: false,
        changeBillingDetailsError: true,
        changeBillingDetailsSuccess: false,
      }
    },
  },
  getDefaultState()
)
